export default class NoScroll {
    constructor(fixedElements) {
        this.fixForIOS12 = true;
        this.gsapScrollTrigger = typeof ScrollTrigger !== 'undefined';

        this.html = document.querySelector('html');
        this.body = document.body;
        this.scrollTop = 0;
        this.scrollWidth = this.getScrollWidth();
        this.fixedElements = document.querySelectorAll(fixedElements);

        this.isScrollOffFlag = false;

        this.createCss();
    }
    createCss() {
        if (this.fixForIOS12) {
            this.css = `
                .noScroll {
                    position: fixed;
                    overflow: hidden;
                }
            `;
        } else {
            this.css = `
            .noScroll {
                overflow: hidden;
            }
        `;
        }

        this.head = document.head || document.getElementsByTagName('head')[0];
        this.style = document.createElement('style');
        this.body.insertBefore(this.style, this.body.childNodes[0]);
        this.style.appendChild(document.createTextNode(this.css));
    }
    getScrollWidth() {
        if ( document.body.scrollHeight <= window.innerHeight ) return 0;
        let outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar";

        this.body.appendChild(outer);

        let widthNoScroll = outer.offsetWidth;
        outer.style.overflow = "scroll";

        let inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);

        let widthWithScroll = inner.offsetWidth;

        outer.parentNode.removeChild(outer);
        return widthNoScroll - widthWithScroll;
    }

    isScrollOff() {
        return this.isScrollOffFlag;
    }

    disableScroll() {

        if (this.fixForIOS12 && this.gsapScrollTrigger) {
            ScrollTrigger.getAll().forEach(st => st.disable());
        }

        this.scrollTop = this.html.scrollTop ? this.html.scrollTop : this.body.scrollTop;
        this.scrollWidth = this.getScrollWidth();

        this.html.classList.add('noScroll');
        this.html.style.top = -this.scrollTop+'px';
        this.html.style.marginRight = this.scrollWidth+'px';
        this.html.style.width = 'calc(100% - '+this.scrollWidth+'px';
        this.isScrollOffFlag = true;


        if (this.fixedElements[0]) {
            [].forEach.call(this.fixedElements, (el, i)=> {
                let elWidth = el.clientWidth;
                let pos = getComputedStyle(el).position;
                let cssWidth = elWidth + this.scrollWidth >= window.innerWidth ? elWidth - this.scrollWidth + 'px' : '';
                if (pos === 'fixed') {
                    el.style.width = cssWidth;
                    el.style.marginRight = this.scrollWidth+'px';
                }
            });
        }
    }
    enableScroll() {
        this.html.classList.remove('noScroll');
        this.html.style.top = '';
        this.html.style.marginRight = '';
        this.html.style.width = '';


        if (this.fixedElements[0]) {
            [].forEach.call(this.fixedElements, (el, i)=> {
                el.style.width = '';
                el.style.marginRight = '';
            });
        }
        this.html.scrollTop = this.scrollTop;
        this.body.scrollTop = this.scrollTop;
        this.scrollTop = 0;
        this.isScrollOffFlag = false;

        if (this.fixForIOS12 && this.gsapScrollTrigger) {
            ScrollTrigger.getAll().forEach(st => st.enable());
        }
    }
}
