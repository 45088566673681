import {isFormValid, setValidation} from "../../utils/setValidation";

document.addEventListener('DOMContentLoaded', () => {

    let groupInputs = document.querySelectorAll('.input-group input');
    groupInputs.forEach(function(el) {
        if (el.value) {
            el.closest('.input-group').classList.add('input-group--notempty');
        }
       el.addEventListener('focus', (e)=> {
           e.currentTarget.closest('.input-group').classList.add('input-group--focus');
       });
       el.addEventListener('blur', (e)=> {
           e.currentTarget.closest('.input-group').classList.remove('input-group--focus');
           if (e.currentTarget.value) {
               e.currentTarget.closest('.input-group').classList.add('input-group--notempty');
           }else{
               e.currentTarget.closest('.input-group').classList.remove('input-group--notempty');
           }
       });
    });
    let eyeList = document.querySelectorAll('.input-group__eye');
    eyeList.forEach(function(el) {
        el.addEventListener('click', function() {
            let elInput = this.closest('.input-group').querySelector('input');
            if (this.classList.contains('input-group__eye--active')) {
                elInput.setAttribute('type', 'password');
            }else{
                elInput.setAttribute('type', 'text');
            }
            this.classList.toggle('input-group__eye--active');
        });
    });

    document.querySelectorAll('.input-adjust-width').forEach(function(el) {
        el.addEventListener('input', function() {
            this.style.width = this.value.length + "ch";
        });
    });

    document.querySelectorAll('.input-group__remove-btn').forEach(function(el) {
        let inputGroup = el.closest('.input-group');
        el.addEventListener('click', function() {
            inputGroup.querySelector('input').value = '';
            inputGroup.classList.remove('input-group--notempty');
        });
    });

    document.querySelectorAll('form.form-check-validation').forEach(function(form) {
        setValidation(form);
        form.addEventListener('submit', function(e) {
            if (!isFormValid(this)) {
                e.preventDefault();
                return false;
            }
        });
    });

});
