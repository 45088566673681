import {checkScrollBlock} from "../../utils/checkScrollBlock";

document.addEventListener('DOMContentLoaded', () => {

    let blocks = document.querySelectorAll('.shadow-block');
    blocks.forEach(function(el) {
        let element = el.querySelector('.shadow-block__wrap');
        checkScrollBlock(el,element);
        element.addEventListener('scroll', function() {
            checkScrollBlock(el,element);
        });
    });
    window.addEventListener('resize', () => {
        blocks.forEach(function(el) {
            let element = el.querySelector('.shadow-block__wrap');
            checkScrollBlock(el,element);
        });
    });

    let shadowBtns = document.querySelectorAll('.shadow-block__btn');
    shadowBtns.forEach(function(el) {
        let block = el.closest('.shadow-block');
        let element = block.querySelector('.shadow-block__wrap');
        el.addEventListener('click', (e) => {
            if (el.classList.contains('shadow-block__btn-left')) {
                element.scrollLeft -= 100;
            }else{
                element.scrollLeft += 100;
            }
        });
    });

});
