import NoScroll from '../../utils/no-scroll';

const noScroll = new NoScroll('.header-main__wrap');

document.addEventListener('DOMContentLoaded', () => {

    const headerMain = document.querySelector('.header-main');
    const burger = document.querySelector('.burger');
    burger && burger.addEventListener('click', (e)=> {
        if (headerMain) {
            e.currentTarget.classList.contains('burger--active') ? closeMenu() : openMenu();
        }
    });

    function openMenu() {
        burger.classList.add('burger--active');
        headerMain.classList.add('header-main--active');
        noScroll.disableScroll();
    }
    function closeMenu() {
        headerMain.classList.remove('header-main--active');
        burger.classList.remove('burger--active');
        noScroll.enableScroll();
    }

    document.addEventListener('click', function(e) {
        if (headerMain && headerMain.classList.contains('header-main--active') && !e.target.closest('.header-main') && !e.target.closest('.burger')) {
            closeMenu();
        }
    });

    const anchors = document.querySelectorAll('a[href*="#"].scroll-to');
    for (let anchor of anchors) {
        anchor.addEventListener('click', function (e) {
            closeMenu();
        })
    }

});

