export function checkScrollBlock(block,inner) {
    if (!block || !inner) {
        return false;
    }
    let currentScroll = inner.scrollTop;
    let currentScrollLeft = inner.scrollLeft;
    let maxScroll = inner.scrollHeight - inner.clientHeight;
    let maxScrollHor = inner.scrollWidth - inner.clientWidth;

    if (maxScroll === 0) {
        block.classList.remove('shadow-top', 'shadow-bottom');
    } else if ( currentScroll > 0 && currentScroll < maxScroll ) {
        block.classList.add('shadow-top', 'shadow-bottom');
    } else if ( currentScroll < maxScroll ) {
        block.classList.add('shadow-bottom');
        block.classList.remove('shadow-top');
    } else if (currentScroll <= maxScroll) {
        block.classList.add('shadow-top');
        block.classList.remove('shadow-bottom');
    }

    if (maxScrollHor === 0) {
        block.classList.remove('shadow-left', 'shadow-right');
    } else if ( currentScrollLeft > 0 && currentScrollLeft < maxScrollHor ) {
        block.classList.add('shadow-left', 'shadow-right');
    } else if ( currentScrollLeft < maxScrollHor ) {
        block.classList.add('shadow-right');
        block.classList.remove('shadow-left');
    } else if (currentScrollLeft <= maxScrollHor) {
        block.classList.add('shadow-left');
        block.classList.remove('shadow-right');
    }
}
