document.addEventListener('DOMContentLoaded', () => {

    const tabLinks = document.querySelectorAll('[data-tab-link]');
    tabLinks.forEach(function(el) {
        el.addEventListener('click', (e) => {
           e.preventDefault();
           let id = e.currentTarget.dataset.tabLink;
           let block = e.currentTarget.closest('[data-tab-block]');
           e.currentTarget.closest('[data-tab-nav]').querySelectorAll('[data-tab-link]').forEach(function(elem) {
                elem.classList.remove('is-active');
            });
           e.currentTarget.classList.add('is-active');
           let tabContents = block.querySelectorAll('[data-tab-content]');
            tabContents.forEach(function(elem) {
               if (elem.dataset.tabContent === id) {
                   elem.classList.add('is-active');
               }else{
                   elem.classList.remove('is-active');
               }
            });
        });
    });

});
