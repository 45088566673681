document.addEventListener('DOMContentLoaded', () => {

    document.addEventListener('click', function(e) {
        if (e.target.closest('.i-select__main')) {
            const select = e.target.closest('.i-select')
            if (select.classList.contains('i-select--active')) {
                select.classList.remove('i-select--active');
            }else{
                document.querySelectorAll('.i-select--active').forEach(function(elem) {
                    elem.classList.remove('i-select--active');
                });
                select.classList.add('i-select--active');
            }
        }

        if (e.target.closest('.i-select__item')) {
            let el = e.target;
            let dropdown = el.closest('.i-select');
            let val = el.dataset.value;
            let itemText = el.textContent;
            if (!el.classList.contains('i-select__item--active')) {
                dropdown.classList.remove('i-select--active');
                dropdown.querySelectorAll('.i-select__item').forEach(function(itemEl) {
                    itemEl.classList.remove('i-select__item--active');
                });
                dropdown.querySelector('.i-select__selected').textContent = itemText;
                dropdown.querySelector('input').classList.remove('error');
                dropdown.querySelector('input').value = val;
                el.classList.add('i-select__item--active');
            }
        }
        if (!e.target.closest('.i-select')) {
            document.querySelectorAll('.i-select--active').forEach(function(el) {
                el.classList.remove('i-select--active');
            });
        }
    });

    const selectSearchList = document.querySelectorAll('.i-select__search input');
    selectSearchList && selectSearchList.forEach(function(el) {
        const items = el.closest('.i-select').querySelectorAll('.i-select__item');
        el.addEventListener('keyup', function() {
            if (this.value) {
                let symbols = this.value.toLowerCase();
                items && items.forEach(function(elem) {
                   let itemName = elem.textContent.toLowerCase();
                    itemName.indexOf(symbols) !== -1 ? elem.classList.remove('i-select__item--hide') : elem.classList.add('i-select__item--hide');
                });
            }else{
                items && items.forEach(function(elem) {
                    elem.classList.remove('i-select__item--hide');
                });
            }
        });
    });

});
