export function setValidation(form) {
    if (form) {
        form.setAttribute('novalidate', true);
        const inputList = form.querySelectorAll('input:required');
        inputList.forEach(function(el) {
            const inputGroup = el.closest('.input-group');
            const errorBlock = (inputGroup) ? inputGroup.querySelector('.input-group__error') : null;

            el.addEventListener('blur', blurHandle.bind(null,el,errorBlock));
            el.addEventListener('input', inputHandle.bind(null,el,errorBlock));
        });
    }
}

function blurHandle(el, errorBlock) {
    checkInputValidation(el, errorBlock);
    el.classList.add('is-touched');
}
function inputHandle(el, errorBlock) {
    if (el.classList.contains('is-touched')) {
        checkInputValidation(el, errorBlock);
    }
}

export function isFormValid(form) {
    const inputList = form.querySelectorAll('input:required');
    let isValid = true;
    inputList.forEach(function(el) {
        const inputGroup = el.closest('.input-group');
        const errorBlock = (inputGroup) ? inputGroup.querySelector('.input-group__error') : null;
        el.classList.add('is-touched');
        checkInputValidation(el, errorBlock);
        if (el.classList.contains('error')) {
            isValid = false;
        }
    });
    return isValid;
}

export function checkInputValidation(input, errorBlock) {
    switch (input !== 'undefined') {
        case input.validity.valueMissing:
            if (errorBlock) errorBlock.textContent = 'Поле должно быть заполнено';
            setInputState(input, false);
            break;
        case !input.value.trim():
            if (errorBlock) errorBlock.textContent = 'Поле должно быть заполнено';
            setInputState(input, false);
            break;
        case input.validity.typeMismatch:
            if (input.type === 'email') {
                if (errorBlock) errorBlock.textContent = 'Некорректный email';
            } else if (input.type === 'url') {
                if (errorBlock) errorBlock.textContent = 'Некорректный url';
            } else {
                if (errorBlock) errorBlock.textContent = 'Некорректное поле';
            }
            setInputState(input, false);
            break;
        case input.type === 'email' && input.validity.patternMismatch:
            if (errorBlock) errorBlock.textContent = 'Некорректный email';
            setInputState(input, false);
            break;
        case input.validity.tooShort:
            if (errorBlock) errorBlock.textContent = `Должно быть не менее ${ input.minLength } символов; вы ввели ${ input.value.length }.`;
            setInputState(input, false);
            break;
        case input.validity.patternMismatch:
            if (errorBlock) errorBlock.textContent = 'Некорректное поле';
            setInputState(input, false);
            break;
        case input.classList.contains('input-repeat-password') && input.closest('form').querySelector('.input-main-password') && input.value !== input.closest('form').querySelector('.input-main-password').value:
            if (errorBlock) errorBlock.textContent = 'Повторите пароль';
            setInputState(input, false);
            break;
        case input.classList.contains('input-main-password'):
            const repeatPasswordInput = input.closest('form').querySelector('.input-repeat-password');
            const repeatPasswordErrorBlock = repeatPasswordInput.closest('.input-group').querySelector('.input-group__error');
            checkInputValidation(repeatPasswordInput, repeatPasswordErrorBlock);
            if (input.validity.valid) {
                if (errorBlock) errorBlock.textContent = '';
                setInputState(input, true);
            }
            break;
        case input.validity.valid:
            if (errorBlock) errorBlock.textContent = '';
            setInputState(input, true);
            break;
    }
}

function setInputState(input, isvalid) {
    if (isvalid) {
        input.classList.remove('error');
        input.classList.add('valid');
    } else {
        input.classList.remove('valid');
        input.classList.add('error');
    }
}
