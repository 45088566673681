import tippy, {roundArrow} from "tippy.js";

export function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {

    document.addEventListener('click', function(e) {
        if (e.target.closest('.input-group-copy__btn')) {
            let input = e.target.closest('.input-group-copy').querySelector('input');
            const instances = tippy(e.target, {
                trigger: 'manual',
                arrow: roundArrow,
                theme: 'light',
                interactive: true,
                appendTo: () => document.body,
                content: (reference) => reference.getAttribute('data-tippy-trigger'),
            });
            if (input.value) {
                copyToClipboard(input.value);
                instances.show();
            }
        }
    });

});
