import {debounce} from "./utils/debounce";

function setCSSVarVH() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setCSSVarVH();
window.addEventListener('resize', debounce(setCSSVarVH, 160));

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.is-default-hidden').forEach(function(el) {
        el.classList.remove('is-default-hidden');
    });
    document.querySelectorAll('.wrapper-rubber--js-no-trans').forEach(function(el) {
        el.classList.remove('wrapper-rubber--js-no-trans');
    });
    document.addEventListener('click', function(e) {
       if (e.target.closest('.btn-alert-close')) {
           e.target.closest('.alert').remove();
       }
    });
});
