import {checkScrollBlock} from "../../utils/checkScrollBlock";

import NoScroll from '../../utils/no-scroll';
import tippy, {roundArrow} from "tippy.js";

const noScroll = new NoScroll('.header');

document.addEventListener('DOMContentLoaded', () => {

    const header = document.querySelector('.header');
    const btn = document.querySelector('.header-menu-btn');
    const headerMenu = document.querySelector('.header-menu');
    const wrapperRubber = document.querySelector('.wrapper-rubber');
    const managerAva = document.querySelector('.header-manager__ava');
    const managerMobBtn = document.querySelector('.header-manager-mob');
    const managerMobBtnClose = document.querySelector('.header-manager__close-btn');
    const mainLinkWithTippy = document.querySelectorAll('.header-menu-nav__main-link[data-tippy-trigger]');

    headerMenu && headerMenu.classList.remove('header-menu--no-trans');

    function checkWrapperState() {
        return ( window.innerWidth >= 1440 && wrapperRubber.classList.contains('wrapper-rubber--desk-short') || window.innerWidth <= 1439 && !wrapperRubber.classList.contains('wrapper-rubber--tablet-long') );
    }

    mainLinkWithTippy.forEach(function(el) {
        const instances = tippy(el, {
            trigger: 'manual',
            arrow: roundArrow,
            theme: 'light',
            placement: 'right',
            appendTo: () => document.body,
            content: (reference) => reference.getAttribute('data-tippy-trigger'),
        });
       el.addEventListener('mouseenter', function() {
           if ( checkWrapperState() ) instances.show();
       });
       el.addEventListener('mouseleave', function() {
           if ( checkWrapperState() ) instances.hide();
       });
    });
    managerAva && managerAva.addEventListener('click', (e) => {
        if ( checkWrapperState() ) toggleHeaderMenu();
    });
    btn && btn.addEventListener('click', (e) => {
        toggleHeaderMenu();
    });

    function toggleHeaderMenu() {
        (window.innerWidth > 1439) ? wrapperRubber.classList.toggle('wrapper-rubber--desk-short') : wrapperRubber.classList.toggle('wrapper-rubber--tablet-long');
        setTimeout(()=> {
            checkScrollBlock(document.querySelector('.shadow-block'),document.querySelector('.shadow-block__wrap'));
        },300);
    }

    const burger = document.querySelector('.header .burger');
    function openMobMenu() {
        burger.classList.add('burger--active');
        header.classList.add('header--active');
        noScroll.disableScroll();
    }
    function closeMobMenu() {
        burger.classList.remove('burger--active');
        header.classList.remove('header--active');
        noScroll.enableScroll();
    }
    burger && burger.addEventListener('click', function(el) { (burger.classList.contains('burger--active')) ? closeMobMenu() : openMobMenu(); });
    const headerMobOverlay = document.querySelector('.header-mob-menu__overlay');
    headerMobOverlay && headerMobOverlay.addEventListener('click', function() {
        closeMobMenu();
    });
    header && window.addEventListener('scroll', function() {
        if (window.innerWidth <= 991) {
            (window.scrollY > 1) ? header.classList.add('header--scrolled') : header.classList.remove('header--scrolled');
        }
    });

    managerMobBtn && managerMobBtn.addEventListener('click', function() {
        headerMenu.classList.add('header-menu--active');
        header.classList.add('header--header-bg');
        noScroll.disableScroll();
    });
    managerMobBtnClose && managerMobBtnClose.addEventListener('click', function() {
        headerMenu.classList.remove('header-menu--active');
        header.classList.remove('header--header-bg');
        noScroll.enableScroll();
    });
    headerMenu && document.addEventListener('click', function(e) {
        if (window.innerWidth <= 991 && headerMenu.classList.contains('header-menu--active') && e.target.closest('.header-menu') && !e.target.closest('.header-manager')) {
            headerMenu.classList.remove('header-menu--active');
            header.classList.remove('header--header-bg');
            noScroll.enableScroll();
        }
    });

});
